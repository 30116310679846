@use 'sass:color';

// Font Variables

$font-primary: 'Montserrat', sans-serif;

// Colour Variables

$pms7696: rgb(99 153 174); // PMS 7696
$pms7545: rgb(66 85 99); // PMS 7545
$primary: $pms7545;
$secondary: hsl(0deg 0% 57%);
$white: white;
$black: black;
$light: white;
$light-blue: color.adjust($pms7696, $lightness: 35%);
$navbar-light: $light-blue;
$dark: $secondary;
$theme-colors: (
  'light': $white,
  'dark': $primary,
  'primary': $primary,
  'secondary': $secondary,
  'info': #6e84c4,
  'success': #3b9166,
  'warning': #deae1b,
  'danger': #dd2a1c,
);
$side-nav-width: 250px;
$link-color: $primary;
$link-hover-color: blue;

$link-color-dark: $light;
$link-hover-color-dark: lighten(blue, 30%);

// Border Variables
$border-radius: 1rem; // .25rem !default;
$border-radius-sm: 1rem; // .2rem !default;
$border-radius-lg: 1rem; // .3rem !default;
$border-radius-pill: 5rem;
$dropdown-border-radius: 0.25rem;
$nav-tabs-border-radius: 1.5rem;
$card-border-radius: 1rem;
$btn-border-radius: 2rem;
$btn-border-radius-sm: 2rem;
$btn-border-radius-lg: 2rem;
$modal-content-border-radius: 2rem;
$list-group-border-radius: 1rem;
$input-border-radius: 2rem;
$input-border-radius-sm: 1rem;
$input-border-radius-lg: 1rem;

// Bootstrap Padding and Margin Variables overrides
$btn-padding-x: 1.5rem;
$btn-padding-x-sm: 1rem;
$btn-padding-x-lg: 2rem;
$modal-inner-padding: 1.5rem;
$tooltip-padding-y: 0.8rem;
$tooltip-padding-x: 1.1rem;
$enable-negative-margins: true;
$modal-inner-padding-negative: calc(#{$modal-inner-padding} * -1);

// Misc. Variables
$rbt-color-primary: white;
$rbt-token-background-color: $primary;
$rbt-border-color-focus: $primary;
$default-hover-percent: 5%;

// active nav tab color
$nav-tabs-link-active-color: $primary;

// Popover
$popover-arrow-color: shade-color($white, 6%);

// shadows
$box-shadow: 0rem 0.5rem 1rem rgba($black, 0.45);

// a nice dark green/blue for dark mode body background
$bg-blue: hsl(196deg 32% 22% / 100%);
