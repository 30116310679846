@use 'sass:color';
@import '~bootstrap/scss/functions';
@import '../../../variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.nav-nav-bar {
  // hide dropdown down caret
  .navbar-top-round-button.dropdown-toggle::after {
    display: none;
  }

  // make navbar's typeahead component a darker primary
  &.navbar .rbt-input-main.form-control.rbt-input {
    background-color: color.adjust($primary, $lightness: -5%); // #384854
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.navbar,
  .new-modal {
    .rounded-circle {
      color: $light-blue;
      border: 0.15rem solid $light-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      text-align: center;
      width: 2em;
      height: 2em;
    }
  }
}
