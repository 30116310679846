/** DO NOT EDIT index.css directly. Use sass to compile index.scss. */

@use 'sass:color';

// Import Bootstrap - note: order is important
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'variables';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
// @import "~bootstrap/scss/pagination";
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
// @import "~bootstrap/scss/carousel";
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';
@import '~bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';

// Typeahead styles
@import '~react-bootstrap-typeahead/css/Typeahead';
@import '~react-bootstrap-typeahead/css/Typeahead.bs5';

@import 'react-datepicker/dist/react-datepicker.css';

// override any CSS variables by theme colour
@include color-mode(dark) {
  // --bs-secondary: #fff;
}

// root
:root {
  --striped: #{$table-striped-bg};
}

// General

body {
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-blue;
  overflow: auto; // clicking nav toggle sets this to inherit or auto
}

html[data-bs-theme='dark'] {
  body {
    background-color: $bg-blue;
  }

  .whiteboard-page {
    background-color: $bg-blue;
  }
}

main {
  padding-left: 0;
}

@include media-breakpoint-up(xl) {
  main {
    padding-left: $side-nav-width;
  }

  .nav-corner {
    left: $side-nav-width;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  color: #666;
  border-bottom: 1px solid #666;
  padding-bottom: 0.25rem;
  margin: 1em 0;
}

h3 {
  font-size: 1rem;
}

// h4 {
//   font-size: 0.95rem;
// }

textarea.form-control {
  border-radius: 1rem;
}

.mentions {
  &__input {
    @extend .form-control;

    overflow: auto !important;
  }

  &__highlighter {
    box-sizing: border-box;
    overflow: hidden;
    // height: 110px;
    padding: $input-padding-y $input-padding-x;
  }

  &__suggestions {
    $common-border: $dropdown-border-width solid $dropdown-border-color;

    overflow: auto;
    margin-top: #{$line-height-base}rem !important;
    max-height: 225px;
    border: $common-border;
    @include box-shadow($dropdown-box-shadow);
    @include border-radius($dropdown-border-radius);

    &__list {
      background-color: $dropdown-bg;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }

    &__item {
      border-top: $common-border;
      padding: 0.5rem 1rem;

      &:first-of-type {
        border-top: none;
      }

      &--focused {
        @include gradient-bg($dropdown-link-hover-bg);
      }
    }
  }
}

.form-label {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.new-modal {
  color: $secondary;

  .rounded-circle {
    color: $secondary;
    border-color: $secondary;
  }
}

// Nav Drawer--Contains nav-left

.nav-drawer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.nav-drawer {
  &.is-closed {
    margin-left: -$side-nav-width;
    opacity: 0;
  }

  padding: 0 1rem;
  width: $side-nav-width;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: $primary;
  height: 100vh;
  z-index: 1020;
  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: none;

  .nav-left a {
    color: $light-blue;

    &:hover {
      color: color.adjust($light-blue, $lightness: -20%);
    }
  }

  a.nav-link {
    padding-left: 0;
  }

  .nav-group-heading {
    font-size: 18px;
    font-family: $font-primary;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: -2.3em;

    .rounded-circle {
      color: $light-blue;
      border: 0.15rem solid $light-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 1.8em;
      height: 1.8em;
      margin-right: 0.5em;
    }
  }

  .nav-left-offcanvas {
    background-color: $secondary;
    padding-left: 1em;

    .nav-left a {
      color: $light-blue;

      &:hover {
        color: color.adjust($light-blue, $lightness: -20%);
      }
    }

    a.nav-link {
      padding-left: 0;
    }
  }
}

// ---Components---

.dashboard {
  .avatar {
    background-color: $primary;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    padding: 0.25rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .pen-stage {
    border: 1px solid $gray-300;
  }

  .stats {
    .list-group-item {
      .icon {
        color: $primary;
        background-color: color.adjust($primary, $lightness: 20%);
        align-self: stretch;
        align-items: center;
        justify-self: stretch;
        display: flex;
        margin-top: -0.5rem;
        margin-left: -1rem !important;
        margin-bottom: -0.5rem;
        padding: 0.5rem;
        font-size: 32px;
      }
    }

    .list-group :first-child .icon {
      border-top-left-radius: 1rem;
    }

    .list-group :last-child .icon {
      border-bottom-left-radius: 1rem;
    }
  }
}

// Class intended to make a table row appear to be clickable
tr.clickable {
  cursor: pointer;
}

.btn-xs {
  padding: 0.15rem 0.25rem;
  font-size: 0.75rem;
}

.btn-outline-primary:hover {
  color: $light;
}

.btn-primary {
  color: $white;

  &:hover {
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.btn-secondary {
  border-color: hsl(0deg 0% 57%);
  color: $white;

  &:hover {
    background-color: color.adjust(hsl(0deg 0% 57%), $lightness: 5%);
    border-color: hsl(0deg 0% 57%);
    color: $white;
  }

  &:focus {
    background-color: color.adjust(hsl(0deg 0% 57%), $lightness: 5%);
    border-color: hsl(0deg 0% 57%);
    color: $white;
  }
}

.btn-link.btn-sm {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.btn.btn-floating {
  padding: 0;
  line-height: 0;
  width: 48px;
  height: 48px;

  &.btn-floating-bottom-right {
    position: fixed;
    bottom: 0.75rem;
    right: 0.75rem;
  }
}

.modal-footer {
  justify-content: space-between;
}

/* Header fixed to the top of the modal */
.modal-header-sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}

/* Footer fixed to the bottom of the modal */
.modal-footer-sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
}

// ---Utilities---

.rounded {
  border-radius: 5rem;
}

.bg-primary-darker {
  background-color: color.adjust($primary, $lightness: -5%);
}

.bg-primary-translucent {
  background-color: rgba($primary, 0.75);
}

.step-num {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border: 2px solid green;
  border-radius: 50%;
}

.coming-soon-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  text-align: center;
  font-size: 2.5rem;
  color: #484848;
  padding-left: 10%;
  padding-right: 10%;
  background-color: rgb(255 255 255 / 50%);
  z-index: 10;
}

.cursor-pointer {
  cursor: pointer;
}

// ---Misc.---

/* Use this with .modal-footer when using a form inside of modal-body */
.modal-footer-in-form {
  margin: 2rem $modal-inner-padding-negative $modal-inner-padding-negative;
}

// since we use dropdowns inside of responsive tables this ensures that the dropdown does not extend below any
// other elements like pagination controls
.table-responsive {
  min-height: 175px;
}

// hide validation indicators on unit selects
.was-validated {
  .form-select.no-validate:valid:not([multiple], [size]),
  .form-select.no-validate:invalid:not([multiple], [size]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }
}

.modal-content {
  box-shadow: 0 1rem 3rem rgba($black, 0.175);
}

// makes background of lightbox modal transparent
.lightbox-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

.lightbox-modal .modal-header {
  border: none;
}

// increases opacity for lightbox modal overlay
.modal-backdrop.show:has(+ .lightbox-modal) {
  opacity: 0.8 !important;
}

// fix for validation in react-typeahead
.was-validated .animal-autocomplete.is-invalid .invalid-feedback {
  display: block;
}

// fix for validation on DailyInputs
.was-validated .daily-inputs:has(.react-datepicker-wrapper.is-invalid) .invalid-feedback {
  display: block;
}

// ---Media Queries---

@include media-breakpoint-down(sm) {
  .form-nav {
    display: none;
  }

  .logo {
    font-size: 1.5em;
  }

  .sign-in-form {
    width: 100%;
    margin: 1rem;
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    display: flex;
    padding: 0 1rem;
    align-items: center;
  }

  .search-icon {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .form-nav {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .search-field {
    min-width: 50%;
  }
}

// inner element for nav radius
.nav-corner {
  display: none;
}

@include media-breakpoint-up(md) {
  .nav-corner {
    display: block;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    left: $side-nav-width;
  }

  .nav-corner.nav-corner-hidden {
    opacity: 0;
    left: 0;
  }
}

// add ellipsis to wide table columns
.td-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
}

.icon-border-muted {
  border-color: #6c757d !important;
}

// Multi line text ellipsis
.text-truncate.text-truncate-clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-truncate.text-truncate-clamp-4 {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

// Width auto responsive classes
.w-auto {
  width: auto !important;
}

@include media-breakpoint-up(sm) {
  .w-auto-sm {
    width: auto !important;
  }
}

@include media-breakpoint-up(md) {
  .w-auto-md {
    width: auto !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-auto-lg {
    width: auto !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-auto-xl {
    width: auto !important;
  }
}

// in MD modal appears way too skinny
@include media-breakpoint-down(lg) {
  .modal-xl {
    width: 100%;
    max-width: $modal-xl;
  }

  .modal-lg {
    width: 100%;
    max-width: $modal-lg;
  }
}

// make active tab more noticeable
.nav-tabs .nav-link.active {
  font-weight: bold;
}

.whiteboard {
  .modal-content,
  &-page {
    background-color: $light-blue;
  }

  &-page {
    //header $modal-header-padding
    padding: 32px;
  }
}

.tooltip.tooltip-unbound .tooltip-inner {
  max-width: unset;
}

.code-block {
  background-color: var(--bs-body);
  padding: 1rem;
}

// Creates heights per breakpoint ie: w-sm-50 or w-lg-auto
$height-sizes: map-get(
  map-get(
    $map: $utilities,
    $key: height,
  ),
  values
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $height-sizes {
    @include media-breakpoint-up($breakpoint) {
      .h-#{$breakpoint}-#{$size} {
        height: $length !important;
      }
    }
  }
}

// Creates widths per breakpoint ie: w-sm-50 or w-lg-auto
$width-sizes: map-get(
  map-get(
    $map: $utilities,
    $key: width,
  ),
  values
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $width-sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

$overflows: map-get(
  map-get(
    $map: $utilities,
    $key: overflow,
  ),
  values
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $overflow in $overflows {
    @include media-breakpoint-up($breakpoint) {
      .overflow-#{$breakpoint}-#{$overflow} {
        overflow: $overflow !important;
      }
    }
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: $primary;
}

// Event Calendar styles
.rbc-btn-group button {
  color: var(--bs-primary-text-emphasis);
}

.rbc-off-range-bg {
  background-color: var(--bs-secondary-bg-subtle);
}

.time-dropdown {
  width: 100%;
}

.time-dropdown .dropdown-menu {
  display: flex !important;
  flex-wrap: wrap;
}

.time-dropdown a {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  text-align: center;
  flex: 1 1 85px;
}

.chatbot {
  position: absolute;
  top: 35;
  width: 500px;
  min-height: calc(100% - 45px);
}

.chatbot-fullscreen {
  width: calc(100vw - $side-nav-width);
  height: calc(100vh - 45px);
  top: 35;
  z-index: 10000;
}

@include media-breakpoint-down(lg) {
  .chatbot {
    position: absolute;
    top: 0;
    min-height: 100%;
  }
}

.vh-25 {
  height: 25vh;
}
.vh-50 {
  height: 50vh;
}
.vh-75 {
  height: 75vh;
}
.vh-100 {
  height: 100vh;
}

.min-vh-25 {
  min-height: 25vh;
}
.min-vh-50 {
  min-height: 50vh;
}
.min-vh-75 {
  min-height: 75vh;
}
.min-vh-100 {
  min-height: 100vh;
}

.max-vh-25 {
  max-height: 25vh;
}
.max-vh-50 {
  max-height: 50vh;
}
.max-vh-75 {
  max-height: 75vh;
}
.max-vh-100 {
  max-height: 100vh;
}

.sticky-column-end {
  position: sticky !important;
  right: 0;
  background-color: #fff; /* Match your table background */
  z-index: 2;
}

.sticky-column-start {
  position: sticky !important;
  left: 0;
  background-color: #fff; /* Match your table background */
  z-index: 2;
}
