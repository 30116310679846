@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.nav-command-search-input {
  input,
  .input-group-text {
    border: none;
  }

  &.search {
    ::placeholder,
    .input-group-text {
      color: #bbb;
    }
    .rbt-input {
      color: white;
    }
  }

  .btn {
    border-color: gray;
    padding: 0 0.75rem;

    &.active {
      background-color: transparent;
      color: white;
      border-right: 1px solid white;
    }

    &.active.first {
      border-right: 2px solid white;
    }

    &.active:focus {
      border-right: 1px solid white;
    }

    &:hover {
      background-color: transparent;
      color: white;
    }
  }

  // sets the width of animal complete container in nav searchbar
  .animal-autocomplete {
    width: 15rem;
  }

  .code-mirror {
    width: 100%;
    max-width: 100%;
    background-color: #384854;
    border-radius: 50rem;

    // disabled dotted border on current line
    .cm-focused {
      outline: none;
    }

    .cm-editor {
      margin-right: 20px;
      height: 36px;

      .cm-scroller {
        overflow: hidden;

        .cm-content {
          max-width: 100px;
          border-radius: 50rem;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .code-mirror {
      width: 100%;

      .cm-editor .cm-scroller .cm-content {
        max-width: 200px;
        width: 200px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .animal-autocomplete {
      width: 100%;
    }
  }
}
