.timeline-node-tooltip {
  html[data-bs-theme='light'] & .btn {
    color: var(--bs-popover-header-color);
  }

  .popover-arrow {
    &::after {
      border-right-color: var(--bs-body-bg) !important;
    }
  }
}
