.copy-btn {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.example-heading {
  margin-bottom: 8px;
  margin-top: 30px;
}

.code-container {
  display: flex;
}

@media (max-width: 576px) {
  .code-container {
    flex-direction: column;
  }

  .copy-btn {
    padding-top: 8px;
    float: right;
    justify-content: flex-end;
  }
}
