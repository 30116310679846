@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.animal-card-events {
  .event-item {
    cursor: pointer;
    .detail-button {
      visibility: hidden;
    }

    &:hover .detail-button {
      visibility: visible;
    }
  }

  .timeline-container {
    width: 40%;

    @include media-breakpoint-only(md) {
      width: 70%;
    }
  }
}

.timeline-node-tooltip {
  width: 28vw;

  @include media-breakpoint-only(lg) {
    width: 24vw;
  }
  @include media-breakpoint-only(xl) {
    width: 20vw;
  }
}
