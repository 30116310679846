@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.page {
  border-radius: 10px;
  background-color: var(--bs-body-bg);
}

@include media-breakpoint-up(sm) {
  .page {
    border-radius: 25px;
  }

  .page h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
}

@include media-breakpoint-down(sm) {
  .page .btn-lg {
    justify-content: center;
    margin: 0 !important;
  }
}
