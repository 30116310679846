@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../src/variables';

.scheduleCalender {
  .rbc-event-label {
    display: none;
  }
  .rbc-today {
    background-color: $pms7696;
  }

  transition: min-height 0.25s ease-in;
}

.schedule-event {
  .rbc-agenda-date-cell {
    background-color: $white !important;
  }

  .rbc-agenda-time-cell,
  .rbc-agenda-event-cell {
    // color: $white;
    background-color: rgba($color: $white, $alpha: 0.8);
  }
}
